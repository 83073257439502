import config from '../config/config'
// import axios from 'axios'
import { axios } from './index'

const baseUrl = `${config.baseUrl}/video/api/v1/video`
export default {
  /**
   * 获取腾讯 `云点播` 签名,
   * 转交 web SDK 发起视频上传调用
   */
  getVodUploadSignature() {
    return axios.post(`${baseUrl}/uploadSignature`).then(res => res.res)
  }
}
